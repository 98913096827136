@keyframes animation-top {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animation-down {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  50% {
    opacity: 1;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes background-color {
  0% {
    background-color: none;
  }

  100% {
    background-color: #235196;
  }
}

.bg-nav {
  background-color: #e7edf4;
  height: 70px;
}

.nav-logo {
  font-size: 25px;
  color: #235196;
  font-weight: bold;
}

.nav {
  padding-top: 10px;
}

@media (max-width: 1024px) {
  .bg-nav {
    background-color: #e7edf4;
    height: 80px;
  }

  .nav {
    padding-top: 20px;
  }

  .menu-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    background: #fff;
    margin: 0 auto;
    text-align: center;
    box-shadow: 1px 4px 10px rgba(#000000, 0.25);

    .navlink {
      cursor: pointer;
      width: 100%;
      text-align: center;
      font-weight: bold;
    }

    .btn-close {
      position: absolute;
      top: 10px;
      right: 20px;
      width: 40px;
      height: 40px;
      background: #2a1447;
      color: #fff;
      border: 1px solid #000;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      cursor: pointer;
      box-shadow: 1px 4px 10px rgba(#000000, 0.25);
    }

    .btn-menu {
      display: flex;
      position: absolute;
      top: 10px;
      left: 30px;
      width: 60px;
      height: 60px;
      background: #5d25a5;
      color: #fff;
      border: 1px solid #000;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      cursor: pointer;
      box-shadow: 1px 4px 10px rgba(#000000, 0.25);
    }

    .active {
      @apply grid grid-cols-1;
      animation: animation-top 0.5s ease-in-out;
      transition: 0.3s all ease-in-out;
    }

    .no-active {
      display: none;
      animation: animation-down 0.5s ease-in-out;
    }
  }
}

.show-anim {
  animation: animation-top 0.5s ease-in-out;
}

.remove-anim {
  animation: animation-down 0.5s ease-in-out;
}

@media (min-width: 1024px) {
  .menu-main {
    display: flex !important;

    .navlink {
      width: 100%;
      text-align: center;
      font-weight: bold;
    }

    .btn-close {
      display: none;
    }

    .btn-menu {
      display: none;
    }

    .active {
      display: flex;
    }

    .no-active {
      display: flex;
    }
  }
}

.menu-main {
  .navlink {
    transition: 0.5s;
    color: #235196;
    border-radius: 25px;
    font-size: 20px;
    // width: 100px;
    // margin-left: 15px;
    text-align: center;
    cursor: pointer;
    @apply px-4;

    &.active {
      background-color: #235196;
      color: white;
    }
  }

  .navlink:hover {
    background-color: #235196;
    color: white;
  }
}

.bell {
  @apply flex items-center justify-around text-4xl text-sky-800 rounded-full cursor-pointer hover:text-white duration-300 hover:bg-sky-800;
  width: 40px;
  height: 40px;
}