@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  background-color: #dde6ef;
  min-height: 100vh;
  color: black !important;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.container {
  max-width: 90%;
  margin: auto;
  font-weight: bold;
}

.btn-eye {
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
}
