.my-btn {
  transition: all 0.35s ease;

  &:hover {
    transition: all 0.35s ease;
    transform: translateY(-5px);
  }
}

.btn-success {
  @apply text-end text-white text-3xl bg-lightgreen hover:bg-darkgreen;
}

.btn-xl-success {
  @apply text-end text-white text-xl bg-lightgreen hover:bg-darkgreen;
}

.btn-danger {
  @apply text-end text-white text-3xl bg-red-500 hover:bg-red-700;
}
