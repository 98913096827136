.stats-card {
  background: white;
  color: black;
}
.view-data {
  background: linear-gradient(
    163deg,
    rgba(35, 81, 150, 1) 0%,
    rgba(91, 129, 176, 1) 100%
  );
}
